const getGalleryPhotos = (scenes) => {
  return scenes
    ?.map((scene) => scene.mediaFiles)
    .flat()
    .filter((file) => {
      const contentType = file.contentType.toLowerCase()
      return file.type === "photo" && contentType !== "image/gif"
    })
}

export { getGalleryPhotos }
