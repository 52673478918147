import { ExtendTemplateGenerationLength, PagePhotosCount, PhotoContainerRatio } from "@book-editor-v2/@types"

import { PAGE_TYPES } from "./page-types"
export * from "./page-style-options"
export * from "./photo-sources"

const PHOTO_CONTAINER_RATIO: PhotoContainerRatio = {
  fill: 3306 / 3366,
  contain: 3306 / 3366,
  four_in_one: 3306 / 3366,
  two_in_one_vertical: 100 / 136,
  two_in_one_horizontal: 136 / 100,
}

const PAGE_PHOTOS_COUNT: PagePhotosCount = {
  fill: 1,
  contain: 1,
  four_in_one: 4,
  two_in_one_vertical: 2,
  two_in_one_horizontal: 2,
}

const EXTEND_TEMPLATE_GENERATION_LENGTH: ExtendTemplateGenerationLength = {
  20: 30,
  40: 60,
  60: 90,
}

const BOOK_SIZES = {
  fill: {
    low: 2020,
  },
  contain: {
    low: 1463,
  },
  four_in_one: {
    low: 366,
  },
  two_in_one_vertical: {
    low: 731,
  },
  two_in_one_horizontal: {
    low: 731,
  },
  cover: { low: 992 },
}

const MINI_BOOK_SIZES_COVER = {
  fill: {
    double: {
      width: 1500,
      height: 1000,
    },
  },
}

const MINI_BOOK_SIZES_PAGES = {
  fill: {
    double: {
      width: 1500,
      height: 1500,
    },
  },
  contain: {
    low: 1000,
  },
  two_in_one_vertical: {
    double: {
      min: 500,
      max: 600,
    },
  },
  two_in_one_horizontal: {
    double: {
      min: 500,
      max: 600,
    },
  },
}

const BOOK_EDITOR_PAGE_STYLES = [
  PAGE_TYPES.fill,
  PAGE_TYPES.contain,
  PAGE_TYPES.two_in_one_vertical,
  PAGE_TYPES.two_in_one_horizontal,
  PAGE_TYPES.four_in_one,
]

const MINI_BOOK_EDITOR_PAGE_STYLES = [
  PAGE_TYPES.fill,
  PAGE_TYPES.contain,
  PAGE_TYPES.two_in_one_vertical,
  PAGE_TYPES.two_in_one_horizontal,
]

export {
  BOOK_EDITOR_PAGE_STYLES,
  BOOK_SIZES,
  EXTEND_TEMPLATE_GENERATION_LENGTH,
  MINI_BOOK_EDITOR_PAGE_STYLES,
  MINI_BOOK_SIZES_COVER,
  MINI_BOOK_SIZES_PAGES,
  PAGE_PHOTOS_COUNT,
  PAGE_TYPES,
  PHOTO_CONTAINER_RATIO,
}
