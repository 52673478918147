import { IGeneratorService, PageStyle, TSettingsGeneratorService } from "@book-editor-v2/@types"
import { EXTEND_TEMPLATE_GENERATION_LENGTH, PAGE_PHOTOS_COUNT } from "@book-editor-v2/constants"

import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"
import { BOOK_PRODUCT, MINI_BOOK_PRODUCT } from "@app/features/product/constants"

export class GeneratorService implements IGeneratorService {
  constructor(private settings: TSettingsGeneratorService = {}) {}

  public generateCover() {
    return {
      photo: {
        id: null,
        offset: {
          x: 0,
          y: 0,
        },
        type: "photo",
      },
    }
  }

  private generatePage() {
    return {
      style: PageStyle.contain,
      photos: [],
    }
  }

  private applyFillPages(pages) {
    let filldiff = 0

    let newPages = pages

    for (let index = 0; index < pages.length; index += 4) {
      newPages[index + filldiff].style = PageStyle.fill
      filldiff = filldiff === 0 ? 1 : 0
    }

    return newPages
  }

  private getPhotoCountByPageStyle(pageStyle: PageStyle): number {
    return PAGE_PHOTOS_COUNT[pageStyle as string]
  }

  private applyPhotos(pages, photosID) {
    let photoIndex = 0

    return pages.map((page) => {
      let generatedPhotos = []
      for (let i = 0; i < this.getPhotoCountByPageStyle(page.style); i++) {
        const photoId = this.settings?.empty ? null : photosID[photoIndex]

        let generatedPhoto = this.generatePhoto(photoId)

        generatedPhotos.push(generatedPhoto)
        photoIndex++
      }

      return {
        ...page,
        photos: generatedPhotos,
      }
    })
  }

  private applyTwoPages(pages) {
    let newPages = pages

    for (let index = 1; index < pages.length; index += 8) {
      newPages[index].style = PageStyle.two_in_one_horizontal
    }

    return newPages
  }

  private applyFourPages(pages) {
    let newPages = pages

    for (let index = 4; index < pages.length; index += 8) {
      newPages[index].style = PageStyle.four_in_one
    }

    return newPages
  }

  private generatePhoto(photo_id: string) {
    return {
      id: photo_id,
      offset: {
        x: 0,
        y: 0,
      },
      type: "photo",
    }
  }

  private getSpacing(photos: number, pages: number): number {
    if (photos < pages) return 1
    return Math.floor(photos / pages)
  }

  private createEmptyArray = (length): Array<null> => {
    return new Array(length).fill(null, 0, length)
  }

  private getPhotosID(photos: any[], length: number): any[any] {
    const creatableLength = photos.length > length ? length : photos.length
    const spacing = this.getSpacing(photos.length, creatableLength)

    return this.createEmptyArray(creatableLength).map((value, index) => {
      return photos[index * spacing].id
    })
  }

  private getPhotosIDFromResources(resources: any[], creatableLength: number): string[] {
    let photosID: string[] = []

    resources.forEach((photos) => {
      if (photosID.length >= creatableLength) return photosID
      photosID = [...photosID, ...this.getPhotosID(photos, creatableLength - photosID.length)]
    })

    return photosID
  }

  private getUniquePhotosFromResources(resources: any[][]): any[][] {
    let matchingArray = []
    return resources.map((resource) => {
      let uniquePhotos = resource.filter((photo) => {
        return matchingArray.findIndex((matchingPhoto) => matchingPhoto.id === photo.id) < 0
      })
      matchingArray = resource
      return uniquePhotos
    })
  }

  private generatePages = (creatableLength) => {
    return this.createEmptyArray(creatableLength).map(() => {
      return this.generatePage()
    })
  }

  private getTemplatePhotosLength = (template: (keyof typeof PageStyle)[]): number =>
    template.reduce((acc, page) => acc + PAGE_PHOTOS_COUNT[page as string], 0)

  private getClientAlbum() {
    const clientAlbumId = localStorage.getItem(CLIENT_ALBUM_ID) || null
    return { id: clientAlbumId }
  }

  public getPhotoIdsByTemplate = (template: (keyof typeof PageStyle)[], resources: any[]) => {
    const uniqueResourcesPhotos = this.getUniquePhotosFromResources(resources)
    const totalPhotosLength = uniqueResourcesPhotos.flat(1).length
    const creatableLength = this.getTemplatePhotosLength(template)
    let photosIDs: string[] = this.getPhotosIDFromResources(uniqueResourcesPhotos, creatableLength)

    return photosIDs
  }

  public generate(cover: any, color: string, length: number, productName: string, resources: Array<any>): string {
    const uniqueResourcesPhotos = this.getUniquePhotosFromResources(resources)
    const totalPhotosLength = uniqueResourcesPhotos.flat(1).length

    let creatanleLength: number = length
    if (totalPhotosLength >= EXTEND_TEMPLATE_GENERATION_LENGTH[length]) {
      creatanleLength = EXTEND_TEMPLATE_GENERATION_LENGTH[length]
    }

    let photosID: string[] = this.getPhotosIDFromResources(uniqueResourcesPhotos, creatanleLength)

    let pages = this.generatePages(length)

    pages = this.applyFillPages(pages)

    if (photosID.length >= EXTEND_TEMPLATE_GENERATION_LENGTH[length]) {
      if (productName === BOOK_PRODUCT) {
        pages = this.applyTwoPages(pages)
        pages = this.applyFourPages(pages)
      }

      if (productName === MINI_BOOK_PRODUCT) {
        pages = this.applyTwoPages(pages)
      }
    }

    pages = this.applyPhotos(pages, photosID)

    const clientAlbum = this.getClientAlbum()

    return JSON.stringify({
      clientAlbum,
      productConfiguration: {
        version: 3,
        cover,
        pages,
      },
      productOptionValues: { color: color, pages: length },
    })
  }
}
