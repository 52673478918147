import { IGetQualityArguments, IQualityService, ISizes, Quality, Rotation } from "@book-editor-v2/@types"

export class QualityService implements IQualityService {
  sizes: ISizes
  hasDouble: boolean
  hasMinMax: boolean

  constructor(sizes: ISizes) {
    this.sizes = sizes
    this.hasDouble = sizes && sizes.hasOwnProperty("double")
    this.hasMinMax = sizes && sizes.hasOwnProperty("double") && sizes.double.hasOwnProperty("max")
  }

  private isLow(size: number): boolean {
    return size < (this.sizes?.low || 0)
  }

  // когда есть ограничения и по ширине, и по высоте (должны выполнятся оба)
  private isLowHasDouble(width: number, height: number): boolean {
    return width < (this.sizes?.double?.width || 0) || height < (this.sizes?.double?.height || 0)
  }

  // когда есть ограничение на max и на min сторону и выполнятся должны оба
  private isLowMinMaxHasDouble(width: number, height: number): boolean {
    // большая сторона width, меньшая height
    if (width > height) return width < (this.sizes?.double?.max || 0) || height < (this.sizes?.double?.min || 0)

    // большая сторона height, меньшая width
    return height < (this.sizes?.double?.max || 0) || width < (this.sizes?.double?.min || 0)
  }

  private isLowMinMaxCoverHasDouble(width: number, height: number): boolean {
    if (width > height) return width < (this.sizes?.double?.min || 0)

    return height < (this.sizes?.double?.min || 0)
  }

  getHorizontalQuality(size: number): Quality {
    if (this.isLow(size)) return Quality.low
    return Quality.normal
  }

  getVerticalQuality(size: number): Quality {
    if (this.isLow(size)) return Quality.low
    return Quality.normal
  }

  getSquareQuality(size: number): Quality {
    if (this.isLow(size)) return Quality.low
    return Quality.normal
  }

  /**
   * @param width
   * @param height
   * @param rotation
   */
  getQuality({ width, height, rotation }: IGetQualityArguments): Quality {
    if (this.hasDouble) {
      if (this.hasMinMax) {
        if (this.isLowMinMaxHasDouble(width, height)) return Quality.low
        return Quality.normal
      } else {
        const isLow = this.isLowHasDouble(width, height)

        if (isLow) {
          return Quality.low
        }

        return Quality.normal
      }
    }
    if (this?.sizes?.cover) {
      if (this.isLowMinMaxCoverHasDouble(width, height)) return Quality.low
      return Quality.normal
    } else {
      if (rotation === Rotation.horizontal) {
        if (this.isLow(height)) return Quality.low
        return Quality.normal
      }

      if (rotation === Rotation.vertical) {
        if (this.isLow(width)) return Quality.low
        return Quality.normal
      }

      throw new Error("rotation undefined")
    }
  }
}
