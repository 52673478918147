import {
  BookCoverColor,
  BookPagesCount,
  IBookEditor,
  IClientAlbumId,
  IProductConfiguration,
  IProductOptions,
  PageStyle,
} from "@book-editor-v2/@types"
import { ProductConfiguration, ProductOptions } from "@book-editor-v2/store"
import { makeAutoObservable, observable } from "mobx"

import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"
import { clientAlbumStore } from "@app/features/client-upload/store"

/**
 * Класс описывающий редактор фотокниги
 */
export class BookEditor implements IBookEditor {
  userEmail: string | null
  public saved: boolean
  public productOptionValues: IProductOptions
  public productConfiguration: IProductConfiguration
  public clientAlbum?: IClientAlbumId
  public isCleanConfiguration?: boolean

  constructor() {
    this.userEmail = null
    this.saved = true
    this.productOptionValues = null
    this.productConfiguration = null
    this.clientAlbum = null
    this.isCleanConfiguration = false

    makeAutoObservable(
      this,
      {
        saved: observable.ref,
        productConfiguration: observable.ref,
        productOptionValues: observable.ref,
        clientAlbum: observable.ref,
        isCleanConfiguration: observable.ref,
      },
      { deep: true }
    )
  }

  /**
   * Установить конфигурацию и опции фотокниги из json
   * @param {string} json в формате строки с содержанием конфигурации и опций для фотокниги
   */
  set JSON(json: string) {
    try {
      const { productConfiguration, productOptionValues, clientAlbum } = JSON.parse(json)
      let { pages, cover } = productConfiguration
      const { pages: length, color } = productOptionValues

      pages = pages.map((page) => ({
        style: PageStyle[page.style],
        photos: page.photos,
      }))

      const clientAlbumId = clientAlbum?.id || localStorage.getItem(CLIENT_ALBUM_ID)
      clientAlbumStore.initClientAlbumById(clientAlbumId)

      this.productOptionValues = new ProductOptions(color as BookCoverColor, length as BookPagesCount)
      this.productConfiguration = new ProductConfiguration(pages, cover, productConfiguration.version, clientAlbumId)
      this.clientAlbum = { id: clientAlbumId }
    } catch (error) {
      console.log("Ошибка установки конфигурации из JSON: ", error)
    }
  }

  /**
   * Состояние готовности редактора
   */
  get isReady() {
    this.saved = true
    return typeof this.productConfiguration?.pages === "object" && typeof this.productConfiguration?.cover === "object"
  }

  /**
   * Получить конфигурацию фотокниги и параметров в формате json строки
   * @return {string}
   */
  get JSON() {
    return JSON.stringify({
      clientAlbum: this.clientAlbum,
      productOptionValues: this.productOptionValues,
      productConfiguration: {
        version: this.productConfiguration.version,
        cover: {
          photo: {
            id: this.productConfiguration.cover.photo.id,
            offset: this.productConfiguration.cover.photo.offset,
            type: this.productConfiguration.cover.photo?.type,
          },
        },
        pages: this.productConfiguration.pages.map((page) => {
          return {
            style: page.style,
            photos: page.photos.map((photo) => ({
              id: photo.id,
              offset: photo.offset,
              type: photo?.type,
            })),
          }
        }),
      },
    })
  }

  get hasUnsavedChanges(): boolean {
    return this.userEmail ? false : !this.saved
  }

  setUserEmail(userEmail: string) {
    this.userEmail = userEmail
  }

  setUnsavedChanges(value: boolean) {
    this.saved = !value
  }

  setCleanConfiguration(value: boolean) {
    this.isCleanConfiguration = value
  }
}
